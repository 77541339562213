import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MySelect from "../components/MySelect.js";
import SelectInstrument from "../components/SelectInstrument.js";
import BaseSelect from "../components/BaseSelect.js";
import SpreadMatrix from "../components/SpreadMatrix.tsx";
import axios from "axios";
import { Spinner } from "@blueprintjs/core";
import MobileWarning from '../components/MobileWarning';

const isFuture = (ins_name) => /\d{6}$/gm.test(ins_name);

const SUPPORTED_SPREAD_TYPES = [
  { label: "Absolute (USD)", value: "absolute" },
  { label: "Relative (basis points)", value: "bps" },
  { label: "Annualised %", value: "annualized_perc" },
];
const SUPPORTED_SPREAD_EXECUTION = [
  { label: "Maker-Taker", value: "maker_taker" },
  { label: "Taker-Taker", value: "taker_taker" },
];

const ChartPage = (props) => {
  const navigate = useNavigate();
  const urlParams = useParams();
  let location = useLocation();
  if (urlParams) {
    // console.log("!!!!! Loading ChartPage with urlParams", urlParams);
  }

  const [validInstruments, setValidInstruments] = useState([]);
  const [baseInstrument, setBaseInstrument] = useState(urlParams.base ? urlParams.base.toUpperCase() : "BTC");

  const [selectedLeg1, setSelectedLeg1] = useState(null);
  const [selectedLeg2, setSelectedLeg2] = useState(null);

  const [spreadType, setSpreadType] = useState({
    label: "Relative (basis points)",
    value: "bps",
  });
  const [spreadExecution, setSpreadExecution] = useState({
    label: "Maker-Taker",
    value: "maker_taker",
  });
  const [spreadData, setSpreadData] = useState();

  const [isParamsExpanded, setIsParamsExpanded] = useState(false);

  // console.log("selectedLeg1", selectedLeg1);
  // console.log("selectedLeg2", selectedLeg2);

  useEffect(() => {
    // console.log("Current BaseInstrument", baseInstrument);
    if (!baseInstrument) {
      // console.log("NO BASE INSTRUMENT");
      return;
    }
    if (baseInstrument === "BTC") {
      // console.log("BASE INSTRUMENT IS BTC");
      setValidInstruments(props.params.instruments["btc"]);
    }
    if (baseInstrument === "ETH") {
      // console.log("BASE INSTRUMENT IS ETH");
      setValidInstruments(props.params.instruments["eth"]);
    }
    if (baseInstrument === "USDC") {
      // console.log("BASE INSTRUMENT IS USDC");
      setValidInstruments(props.params.instruments["usdc"]);
    }
  }, [baseInstrument, props.params]);

  useEffect(() => {
    if (validInstruments) {
      if (urlParams) {
        if (urlParams && urlParams.spreadType) {
          setSpreadType(findValue(SUPPORTED_SPREAD_TYPES, urlParams.spreadType));
        }
        if (urlParams && urlParams.execType) {
          setSpreadExecution(findValue(SUPPORTED_SPREAD_EXECUTION, urlParams.execType));
        }
      }

      // console.log("=-=-=-=-", urlParams && urlParams.leg1Id && urlParams.venueId1 && urlParams.leg2Id && urlParams.venueId2);

      if (urlParams && urlParams.leg1Id && urlParams.venueId1 && urlParams.leg2Id && urlParams.venueId2) {
        // console.log("Trying to set default selected legs FROM URL and ", validInstruments.length, "valid instruments");
        let leg1 = null,
          leg2 = null;

        for (let i = 0; i < validInstruments.length; i++) {
          if (validInstruments[i].ins_id === parseInt(urlParams.leg1Id) && validInstruments[i].venue_key_id === parseInt(urlParams.venueId1)) {
            leg1 = validInstruments[i];
          }
          if (validInstruments[i].ins_id === parseInt(urlParams.leg2Id) && validInstruments[i].venue_key_id === parseInt(urlParams.venueId2)) {
            leg2 = validInstruments[i];
          }
        }
        if (!leg1 || !leg2) {
          // console.log("No valid leg1 or leg2 found - setting defaults");
          setDefaultSelectedLegs(validInstruments);
        } else {
          // console.log("Setting leg 1", leg1);
          setSelectedLeg1(leg1);
          // console.log("Setting leg 2", leg2);
          setSelectedLeg2(leg2);
        }
      } else {
        setDefaultSelectedLegs(validInstruments);
      }
    }
  }, [urlParams, validInstruments]);

  // Spread Data Loading logic
  useEffect(() => {
    // console.log("SPREAD DATA LOAD LOGIC START", baseInstrument, selectedLeg1, selectedLeg2, spreadType, spreadExecution);
    // console.log(baseInstrument != null && selectedLeg1 != null && selectedLeg2 != null && spreadType != null && spreadExecution != null);
    if (baseInstrument != null && selectedLeg1 != null && selectedLeg2 != null && spreadType != null && spreadExecution != null) {
      const targetUrl = `/${baseInstrument.toLowerCase()}/${spreadExecution.value}/${spreadType.value}/${selectedLeg1.venue_key_id}/${selectedLeg1.ins_id}/${
        selectedLeg2.venue_key_id
      }/${selectedLeg2.ins_id}`;
      // console.log(targetUrl);
      if (location.pathname !== targetUrl) {
        // console.log("current location", location.pathname);

        // var str_var = "/btc/maker_taker/bps/1/7/1/13365";
        // console.log("current path", location.pathname, location.pathname.length);
        if (location.pathname.length > 1) {
          var splitArray = location.pathname.split("/");
          // const current_base = splitArray[1]; // "btc"
          if (splitArray.length === 8) {
            const current_spread_type = splitArray[3]; // "maker_taker"
            const current_execution_style = splitArray[2]; // "bps"
            const venueId1 = splitArray[4]; // "1"
            const leg1Id = splitArray[5]; // "7"
            const venueId2 = splitArray[6]; // "1"
            const leg2Id = splitArray[7]; // "13365"

            if (
              current_spread_type !== spreadType.value ||
              current_execution_style !== spreadExecution.value ||
              venueId1 !== selectedLeg1.venue_key_id.toString() ||
              leg1Id !== selectedLeg1.ins_id.toString() ||
              venueId2 !== selectedLeg2.venue_key_id.toString() ||
              leg2Id !== selectedLeg2.ins_id.toString()
            ) {
              // console.log(current_spread_type !== spreadType.value);
              // console.log(current_execution_style !== spreadExecution.value);
              // console.log(venueId1 !== selectedLeg1.venue_key_id.toString());
              // console.log(leg1Id !== selectedLeg1.ins_id.toString());
              // console.log(venueId2 !== selectedLeg2.venue_key_id.toString());
              // console.log(leg2Id !== selectedLeg2.ins_id.toString());

              // console.log("NAVIGATEing 1 from", location.pathname);
              // console.log("NAVIGATEing 1 to", targetUrl);
              fetchSetSpreadData({
                spreadType: spreadType.value,
                execType: spreadExecution.value,
                leg1Id: selectedLeg1.ins_id,
                leg2Id: selectedLeg2.ins_id,
                venueId1: selectedLeg1.venue_key_id,
                venueId2: selectedLeg2.venue_key_id,
              });
              navigate(targetUrl, { replace: true });
            } else {
            }
          } else {
          }
        } else {
          // console.log("NAVIGATE 3", targetUrl);
          fetchSetSpreadData({
            spreadType: spreadType.value,
            execType: spreadExecution.value,
            leg1Id: selectedLeg1.ins_id,
            leg2Id: selectedLeg2.ins_id,
            venueId1: selectedLeg1.venue_key_id,
            venueId2: selectedLeg2.venue_key_id,
          });
          // console.log("NAVIGATEing 2 from", location.pathname);
          // console.log("NAVIGATEing 2 to", targetUrl);
          navigate(targetUrl, { replace: true });
        }
      } else {
        if (!spreadData) {
          fetchSetSpreadData({
            spreadType: spreadType.value,
            execType: spreadExecution.value,
            leg1Id: selectedLeg1.ins_id,
            leg2Id: selectedLeg2.ins_id,
            venueId1: selectedLeg1.venue_key_id,
            venueId2: selectedLeg2.venue_key_id,
          });
        }
      }
    } else {
      // console.log("NOT CONSIDERING TO LOAD SPREAD DATA");
    }
  }, [selectedLeg1, selectedLeg2, spreadType, spreadExecution, baseInstrument, navigate, location, spreadData]);

  function fetchSetSpreadData(spreadDataParams) {
    // {spreadType: String, execType: String, leg1Id: Number, leg2Id: Number, venueId1: Number, venueId2: Number}
    // console.log("Loaded with params", spreadDataParams);
    const dataRequestTarget = `${window.APP_CONFIG.REACT_APP_BACKEND_URL}/historical_spread/${spreadDataParams.spreadType}/${spreadDataParams.execType}/${spreadDataParams.venueId1}/${spreadDataParams.leg1Id}/${spreadDataParams.venueId2}/${spreadDataParams.leg2Id}`;
    // console.log("Making request to", dataRequestTarget);
    axios
      .get(dataRequestTarget)
      .then((res) => {
        // console.log("Setting spread data!!!!!!");
        setSpreadData(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  function setDefaultSelectedLegs(instruments) {
    // console.log("SETTING DEFAULT LEGS");
    for (let i = 0; i < instruments.length; i++) {
      if (instruments[i].ins_name === "BTC/USDC" && instruments[i].venue_key_name === "deribit-live") {
        setSelectedLeg1(instruments[i]);
        break;
      }
      if (instruments[i].ins_name === "ETH/USDC" && instruments[i].venue_key_name === "deribit-live") {
        setSelectedLeg1(instruments[i]);
        break;
      }
      if (instruments[i].ins_name === "USD/ZAR" && instruments[i].venue_key_name === "fxfeed") {
        setSelectedLeg1(instruments[i]);
        break;
      }
    }
    const futures = [];
    for (let i = 0; i < instruments.length; i++) {
      if (isFuture(instruments[i].ins_name) && instruments[i].venue_key_name === "deribit-live") {
        futures.push(instruments[i]);
      }
    }
    futures.sort((a, b) => {
      if (a.ins_name < b.ins_name) {
        return -1;
      }
      if (a.ins_name > b.ins_name) {
        return 1;
      }
      return 0;
    });
    if (futures.length > 0) {
      setSelectedLeg2(futures.slice(-1)[0]);
    } else {
      for (let i = 0; i < instruments.length; i++) {
        if (instruments[i].ins_name === "USDC/ZAR" && instruments[i].venue_key_name === "valr-readonly") {
          setSelectedLeg2(instruments[i]);
          break;
        }
      }
    }
  }

  function findVenueInstrument(value) {
    if (validInstruments) {
      for (const option of validInstruments) {
        const venue_ins = value.split("|");
        if (venue_ins[0] === option.venue_key_name && venue_ins.slice(1).join() === option.ins_name) {
          return option;
        }
      }
    }
    // console.log("No VenueInstrument found", value);
  }

  function findLabel(options, label) {
    return options.find((base) => base.label === label);
  }

  function findValue(options, value) {
    return options.find((base) => base.value === value);
  }

  let spread_data;
  let leg_1_data;
  if (spreadData) {
    spread_data = spreadData.spread; //.sort((a, b) => a.x - b.x);
    leg_1_data = spreadData.leg_1;
  }

  // Function to get summary text
  const getParameterSummary = () => {
    if (!selectedLeg1 || !selectedLeg2) return '';
    
    const leg1Name = selectedLeg1.ins_name;
    const leg2Name = selectedLeg2.ins_name;
    const executionType = spreadExecution.value === 'maker_taker' ? 'M/T' : 'T/T';
    const spreadTypeShort = spreadType.value === 'bps' ? 'bps' : 
                           spreadType.value === 'absolute' ? 'USD' : '%';

    return `${leg1Name} vs ${leg2Name} (${executionType}, ${spreadTypeShort})`;
  };

  return (
    <div className="flex flex-col space-y-4">
      <MobileWarning />
      
      {/* Controls Panel */}
      <div className="bg-white rounded-lg shadow-sm border">
        {/* Header with toggle */}
        <div 
          className={`flex items-center justify-between px-4 py-2.5 cursor-pointer 
            hover:bg-gray-50 transition-colors group
            ${!isParamsExpanded ? 'hover:bg-blue-50/50' : 'hover:bg-gray-50'}`}
          onClick={() => setIsParamsExpanded(!isParamsExpanded)}
          role="button"
          aria-expanded={isParamsExpanded}
          tabIndex={0}
        >
          <div className="flex items-center gap-2 min-w-0">
            <div className="flex items-center gap-1.5">
              <h2 className="text-sm font-medium text-gray-700">Parameters</h2>
              {/* Expand/Collapse hint */}
              <span className={`text-xs text-gray-400 group-hover:text-gray-500 transition-colors
                ${isParamsExpanded ? 'hidden' : 'inline-flex items-center gap-0.5'}`}
              >
                <span className="hidden sm:inline">(click to expand)</span>
                <span className="sm:hidden">↓</span>
              </span>
            </div>
            {!isParamsExpanded && selectedLeg1 && selectedLeg2 && (
              <>
                <span className="text-gray-400 select-none hidden sm:inline">|</span>
                <span className="text-sm text-gray-600 font-normal truncate max-w-[calc(100vw-200px)] sm:max-w-[600px]">
                  {getParameterSummary()}
                </span>
              </>
            )}
          </div>
          <button 
            className={`text-gray-400 group-hover:text-gray-600 transition-all duration-200 flex-shrink-0
              ${!isParamsExpanded ? 'group-hover:text-blue-500' : ''}`}
            style={{ transform: isParamsExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
            aria-label={isParamsExpanded ? "Collapse parameters" : "Expand parameters"}
          >
            <svg 
              className="w-5 h-5" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M19 9l-7 7-7-7" 
              />
            </svg>
          </button>
        </div>

        {/* Collapsible content */}
        <div 
          className={`transition-all duration-200 ease-in-out overflow-hidden
            ${isParamsExpanded ? 'max-h-[500px] sm:max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}
        >
          <div className="p-3 border-t">
            {/* Top Row - Base Currency */}
            <div className="flex justify-center mb-3">
              <div className="w-48">
                <label className="block text-xs font-medium text-gray-500 mb-1 text-center">Base Currency</label>
                <BaseSelect
                  onValueChange={(e) => {
                    setValidInstruments([]);
                    setBaseInstrument(e.target.id.toUpperCase());
                  }}
                  value={baseInstrument}
                />
              </div>
            </div>

            {/* Middle Row - Spread Type and Execution */}
            <div className="flex flex-col sm:flex-row justify-center gap-4 mb-3">
              <div className="w-full sm:w-56">
                <label className="block text-xs font-medium text-gray-500 mb-1">Spread Type</label>
                <MySelect
                  name="SpreadCalculation"
                  menuItems={SUPPORTED_SPREAD_TYPES}
                  value={spreadType ? spreadType.label : ""}
                  onValueChange={(value) => setSpreadType(findLabel(SUPPORTED_SPREAD_TYPES, value))}
                />
              </div>
              <div className="w-full sm:w-48">
                <label className="block text-xs font-medium text-gray-500 mb-1">Execution</label>
                <MySelect
                  name="Execution"
                  menuItems={SUPPORTED_SPREAD_EXECUTION}
                  value={spreadExecution ? spreadExecution.label : ""}
                  onValueChange={(value) => setSpreadExecution(findLabel(SUPPORTED_SPREAD_EXECUTION, value))}
                />
              </div>
            </div>

            {/* Bottom Row - Instrument Selection */}
            <div className="flex flex-col sm:flex-row items-center justify-center gap-2">
              <div className="w-full sm:w-64">
                <label className="block text-xs font-medium text-gray-500 mb-1">Leg 1</label>
                <SelectInstrument
                  name="Leg 1"
                  menuItems={validInstruments}
                  value={selectedLeg1 ? selectedLeg1.venue_key_name + "|" + selectedLeg1.ins_name : ""}
                  onValueChange={(value) => setSelectedLeg1(findVenueInstrument(value))}
                />
              </div>
              <div className="flex items-center sm:items-end mb-1">
                <span className="text-gray-400 font-medium px-1">vs</span>
              </div>
              <div className="w-full sm:w-64">
                <label className="block text-xs font-medium text-gray-500 mb-1">Leg 2</label>
                <SelectInstrument
                  name="Leg 2"
                  menuItems={validInstruments}
                  value={selectedLeg2 ? selectedLeg2.venue_key_name + "|" + selectedLeg2.ins_name : ""}
                  onValueChange={(value) => setSelectedLeg2(findVenueInstrument(value))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Chart */}
      <div className="bg-white rounded-lg shadow-sm border p-4">
        {!spread_data && (
          <div className="flex items-center justify-center h-[70vh]">
            <Spinner aria-label="Loading..." intent="primary" size={73} />
          </div>
        )}
        {spread_data && (
          <SpreadMatrix 
            spread_data={spread_data} 
            leg_1_data={leg_1_data}
            leg1Name={selectedLeg1.ins_name}
            leg2Name={selectedLeg2.ins_name}
            spreadType={spreadType.value}
          />
        )}
      </div>
    </div>
  );
};

export default ChartPage;
