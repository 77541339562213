import React from "react";

const BaseSelect = (props) => {
  const radios = ["ETH", "USDC"];

  return (
    <div className="flex flex-col p-1">
      {/* <h2 className="text-gray-800 font-medium">Select Base Currency</h2> */}

      <ul className="mt-1 space-y-1">
        <div className="flex flex-row items-center justify-center">
          {/* Radio */}
          <li className="flex items-center gap-x-2.5 px-2">
            <input
              type="radio"
              name="base"
              checked={props.value === "BTC"}
              id={"BTC"}
              className="form-radio border-gray-400 text-indigo-600 focus:ring-indigo-600 duration-150"
              onChange={props.onValueChange}
            />
            <label htmlFor="BTC" className="text-sm text-gray-700 font-medium">
              BTC
            </label>
          </li>
          {/* Radio groups */}
          {radios.map((item, idx) => (
            <li key={idx} className="flex items-center gap-x-2.5 px-2">
              <input
                type="radio"
                name="base"
                id={item}
                checked={props.value === item}
                className="form-radio border-gray-400 text-indigo-600 focus:ring-indigo-600 duration-150"
                onChange={props.onValueChange}
              />
              <label
                htmlFor={idx}
                className="text-sm text-gray-700 font-medium"
              >
                {item}
              </label>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
};

export default BaseSelect;
