import { redirect, defer } from "react-router-dom";
import { OptionProps } from "@blueprintjs/core";

export async function parameterLoader() {
  /*
    Returns a promise that resolves with the data needed to populate the parameter selection input fields on the page.

     */

  const SUPPORTED_BASE_INSTRUMENTS: OptionProps<string>[] = [
    { label: "BTC", value: "btc" },
    { label: "ETH", value: "eth" },
    { label: "USDC", value: "usdc" },
  ];

  const SUPPORTED_SPREAD_TYPES: OptionProps<string>[] = [
    { label: "Absolute (USD)", value: "absolute" },
    { label: "Relative (basis points)", value: "bps" },
    { label: "Annualised %", value: "annualized_perc" },
  ];
  const SUPPORTED_SPREAD_EXECUTION: OptionProps<string>[] = [
    { label: "Maker-Taker", value: "maker_taker" },
    { label: "Taker-Taker", value: "taker_taker" },
  ];

  const response = await fetch(
    `${window.APP_CONFIG.REACT_APP_BACKEND_URL}/historical_spread/parameters`
  );
  if (!response.ok) {
    console.error("Get algo details failed");
    return redirect("/logout");
  } else {
    const resData = (await response.json()) as {
      data: { btc: string[]; eth: string[] };
    };
    return {
      SUPPORTED_BASE_INSTRUMENTS,
      SUPPORTED_SPREAD_TYPES,
      SUPPORTED_SPREAD_EXECUTION,
      instruments: resData.data,
    };
  }
}

export async function deferredParameterLoader({ params }) {
  const parameterPromise = parameterLoader();

  return defer({
    allParameters: parameterPromise,
  });
}
