import { useState, useEffect } from 'react';

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`sticky top-0 z-50 backdrop-blur-md transition-all duration-200
      ${isScrolled 
        ? 'bg-white/75 shadow-sm' 
        : 'bg-white/95'
      }`}
    >
      <div className="max-w-screen-xl mx-auto flex items-center justify-between px-4 py-3">
        {/* Left side - Brand */}
        <div className="flex items-center space-x-2">
          <a href="/" className="flex items-center space-x-2 hover:opacity-80 transition">
            <img src="/spreadalgo.png" alt="SpreadAlgo" className="h-8 w-auto" />
            <span className="font-semibold text-xl text-gray-800">SpreadAlgo.com</span>
          </a>
        </div>

        {/* Right side - Links */}
        <div className="flex items-center space-x-6">
          <a 
            href="https://FwdCrv.com" 
            target="_blank" 
            rel="noreferrer"
            className="text-gray-600 hover:text-gray-800 transition flex items-center gap-2"
            title="Real-time Forward Curve View"
          >
            <img src="/fwdcrv.png" alt="FwdCrv" className="h-8 w-auto" />
            <span className="text-sm text-gray-500 hidden sm:inline">Real-time Forward Curve View</span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
