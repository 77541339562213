import "./App.css";
import RootLayout from "./pages/RootLayout";
import ChartPage from "./pages/ChartPage";
import { deferredParameterLoader } from "./utils/ParameterLoader.tsx";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <div>Ooops... something went wrong.</div>,
      id: "root",
      loader: deferredParameterLoader,
      children: [
        {
          path: ":base/:execType/:spreadType/:venueId1/:leg1Id/:venueId2/:leg2Id",
          id: "spread-timeseries-full",
          element: <ChartPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
