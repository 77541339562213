import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useRouteLoaderData } from "react-router";
import ChartPage from "./ChartPage";
import { Spinner } from "@blueprintjs/core";
import { Await } from "react-router-dom";
import { Suspense } from "react";

function RootLayout() {
  const data = useRouteLoaderData("root");

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      <div className="w-full bg-gray-50 border-b">
        <div className="max-w-screen-xl mx-auto px-4 py-3">
          <p className="text-gray-600 text-sm text-center">
            A Historical Spread (Long vs Short) timeseries for cryptocurrency markets. <a href="https://vanderwalt.de" className="text-blue-600 hover:text-blue-800 underline">Contact me for any questions.</a>
          </p>
        </div>
      </div>
      <main className="flex-1 container mx-auto px-4 py-6">
        <Suspense
          fallback={
            <div className="flex items-center justify-center h-[70vh]">
              <Spinner aria-label="Loading..." intent="primary" size={73} />
            </div>
          }
        >
          <Await
            resolve={data.allParameters}
            errorElement={<p>Error loading parameters!</p>}
          >
            {(resolvedParams) => <ChartPage params={resolvedParams}/>}
          </Await>
        </Suspense>
      </main>
      <Footer />
    </div>
  );
}

export default RootLayout;
