/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, PointElement, LineController, LineElement, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, PointElement, LineController, LineElement, zoomPlugin, ...registerables);

type SpreadMatrixProps = {
  spread_data: Point[];
  leg_1_data: Point[];
  leg1Name: string;
  leg2Name: string;
  spreadType: string;
};

export default function SpreadMatrix(props: SpreadMatrixProps) {
  // Helper function to get spread type label
  const getSpreadTypeLabel = () => {
    switch (props.spreadType) {
      case 'bps':
        return 'Basis Points';
      case 'absolute':
        return 'Absolute (USD)';
      case 'annualized_perc':
        return 'Annualised %';
      default:
        return '';
    }
  };

  const chartConfig = {
    type: "line",
    data: {
      datasets: [
        {
          label: "Spread",
          data: props.spread_data.map((p) => ({ x: p.x, y: p.y })),
          showLine: true,
          borderColor: "#BC3D41",
          backgroundColor: "#BC3D41",
          pointStyle: false,
          borderWidth: 1,
          yAxisID: "yAxisRight",
        },
        {
          label: props.leg1Name,
          data: props.leg_1_data.map((p) => ({ x: p.x, y: p.y })),
          showLine: true,
          borderColor: "#111111",
          backgroundColor: "#111111",
          pointStyle: false,
          borderWidth: 1,
          borderDash: [5, 5],
          yAxisID: "yAxisLeft",
        },
      ],
    },
    options: {
      scales: {
        x: {
          type: "time",
          time: {
            displayFormats: {
              minute: "YYYY-MM-DD HH:mm",
            },
          },
          ticks: { source: "auto" },
          grid: {
            drawBorder: true,
            drawOnChartArea: false,
            drawTicks: true,
          },
          title: {
            display: true,
            text: "Date & Time",
            color: "#222222",
            padding: { top: 10 }
          },
          font: {
            size: 8,
          },
        },
        yAxisLeft: {
          position: "left",
          title: {
            display: true,
            text: `${props.leg1Name} Mid Price`,
            color: "#222222",
            padding: { bottom: 10 }
          },
          grid: {
            display: true,
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
        yAxisRight: {
          position: "right",
          title: {
            display: true,
            text: `${getSpreadTypeLabel()} Spread (${props.leg1Name.includes(':') ? props.leg1Name.split(':')[1] : props.leg1Name} vs ${props.leg2Name.includes(':') ? props.leg2Name.split(':')[1] : props.leg2Name})`,
            color: "#222222",
            padding: { bottom: 10 }
          },
          grid: {
            display: true,
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      },
      plugins: {
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true,
            padding: 20,
          }
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "xy",
          },
        },
      },
    },
  };

  return <Chart style={{ height: "75vh" }} type="line" data={chartConfig.data} options={chartConfig.options} />;
}
